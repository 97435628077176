import React from "react";
import "./Member.css";

// import { Col, Container, Row } from "react-bootstrap";
import Joinus from "../../pages/JoinUs/JoinUs";
function Member() {
  return (
    <div></div>
    
   
  );
}

export default Member;
