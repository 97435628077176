import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadingSpinner from "../src/LoadingSpinner/LoadingSpinner";

import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Member from "./pages/Become Member/Member";

const App = () => {
  const Header = lazy(() => import("./compounds/AppBar/AppBar"));
  const Footer = lazy(() => import("./compounds/Footer/Footer"));
  const Button = lazy(() => import("./compounds/Button/Button"));
  const Home = lazy(() => import("./pages/Home/Home"));
  const About = lazy(() => import("./pages/About/About"));
  const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
  const Services = lazy(() => import("./pages/Services/Services"));
  
  const TandC = lazy(() => import("./pages/Terms&Conditions/TandC"));
  const PolicyPrivacy = lazy(() =>
    import("./pages/PolicyPrivacy/PolicyPrivacy")
  );
  
  const OfficePackage = lazy(() =>
    import("./pages/ProductSourcingPages/OfficeSupplies/OfficePackage")
  );
  const ClothingandApparel = lazy(() =>
    import("./pages/ProductSourcingPages/Clothing&Apparel/ClothingandApparel")
  );
  const Technology = lazy(() =>
    import("./pages/ProductSourcingPages/Technology/Technology")
  );
  const RawMaterial = lazy(() =>
    import("./pages/ProductSourcingPages/RawMaterial/RawMaterial")
  );
  const Toys = lazy(() => import("./pages/ProductSourcingPages/Toys/Toys"));
  const GetStarted = lazy(() => import("./pages/GetStarted/GetStarted"));
  const Web = lazy(() =>
    import("./pages/ProductSourcingPages/WebApplication/Web")
  );
  const Crm = lazy(() => import("./pages/ProductSourcingPages/Crm/Crm"));
  const Digital = lazy(() =>
    import("./pages/ProductSourcingPages/Digitalmedia/Digital")
  );
  const BusnessIt = lazy(() =>
    import("./pages/ProductSourcingPages/BusinessIt/BusnessIt")
  );
  const Careers = lazy(() => import("./pages/Careers/Careers"));
  const TechJd = lazy(() => import("./pages/Jobdescriptions/TechJd"));
  const SeoJd = lazy(() => import("./pages/Jobdescriptions/SeoJd"));
  const SalesJd = lazy(() => import("./pages/Jobdescriptions/SalesJd"));
  const DigitalmarketingJd = lazy(() =>
    import("./pages/Jobdescriptions/DigitalmarketingJd")
  );
  const Contactform = lazy(() => import("./compounds/ContactForm/ContactForm"));
  const Error = lazy(() => import("./compounds/Error404/Error"));
 
  return (
    <Router>
      
      <Suspense fallback={<LoadingSpinner />}>
        <Header />
        <Button />
        <Routes>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
         
          <Route path="/terms-conditions" element={<TandC />} />
          <Route path="/privacy-policy" element={<PolicyPrivacy />} />
          <Route path="/ofcpkg" element={<OfficePackage />} />
          <Route path="/clothing-apparel" element={<ClothingandApparel />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/rawmaterial" element={<RawMaterial />} />
          <Route path="/toys" element={<Toys />} />
          <Route path="/getstarted" element={<GetStarted />} />
          <Route path="/webdesign&application" element={<Web />} />
          <Route path="/crmintegration" element={<Crm />} />
          <Route path="/digitalmedia" element={<Digital />} />
          <Route path="/Bisiness&itconsulting" element={<BusnessIt />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/techjs" element={<TechJd />} />
          <Route path="/seojs" element={<SeoJd />} />
          <Route path="/salesjs" element={<SalesJd />} />
          <Route path="/digitalmarketingjs" element={<DigitalmarketingJd />} />
          <Route path="/contact-us" element={<Contactform />} />
          <Route path="*" element={<Error />} />
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;
